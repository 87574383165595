{
  "asyncapi": "3.0.0",
  "info": {
    "title": "Springwolf example project - Kafka",
    "version": "1.0.0",
    "description": "This group only contains endpoints that are related to vehicles.",
    "termsOfService": "http://asyncapi.org/terms",
    "contact": {
      "name": "springwolf",
      "url": "https://github.com/springwolf/springwolf-core",
      "email": "example@example.com"
    },
    "license": {
      "name": "Apache License 2.0"
    },
    "x-apitype": "internal",
    "x-generator": "springwolf"
  },
  "defaultContentType": "application/json",
  "servers": {
    "kafka-server": {
      "host": "kafka:29092",
      "protocol": "kafka"
    }
  },
  "channels": {
    "vehicle-topic": {
      "address": "vehicle-topic",
      "messages": {
        "io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase": {
          "$ref": "#/components/messages/io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase"
        }
      },
      "bindings": {
        "kafka": {
          "bindingVersion": "0.5.0"
        }
      }
    }
  },
  "components": {
    "schemas": {
      "SpringKafkaDefaultHeaders-VehicleBase": {
        "title": "SpringKafkaDefaultHeaders-VehicleBase",
        "type": "object",
        "properties": {
          "__TypeId__": {
            "type": "string",
            "description": "Spring Type Id Header",
            "enum": [
              "io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase"
            ],
            "examples": [
              "io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase"
            ]
          }
        },
        "examples": [
          {
            "__TypeId__": "io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase"
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "properties": {
            "__TypeId__": {
              "description": "Spring Type Id Header",
              "enum": [
                "io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase"
              ],
              "type": "string"
            }
          },
          "title": "SpringKafkaDefaultHeaders-VehicleBase",
          "type": "object"
        }
      },
      "io.github.springwolf.examples.kafka.dtos.discriminator.EnginePower": {
        "title": "EnginePower",
        "type": "object",
        "properties": {
          "hp": {
            "type": "integer",
            "format": "int32"
          },
          "torque": {
            "type": "integer",
            "format": "int32"
          }
        },
        "examples": [
          {
            "hp": 0,
            "torque": 0
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "properties": {
            "hp": {
              "format": "int32",
              "type": "integer"
            },
            "torque": { }
          },
          "title": "EnginePower",
          "type": "object"
        }
      },
      "io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase": {
        "discriminator": "vehicleType",
        "title": "VehicleBase",
        "type": "object",
        "properties": {
          "enginePower": {
            "$ref": "#/components/schemas/io.github.springwolf.examples.kafka.dtos.discriminator.EnginePower"
          },
          "powerSource": {
            "type": "string"
          },
          "topSpeed": {
            "type": "integer",
            "format": "int32"
          },
          "vehicleType": {
            "type": "string"
          }
        },
        "description": "Demonstrates the use of discriminator for polymorphic deserialization (not publishable)",
        "examples": [
          {
            "batteryCapacity": 0,
            "chargeTime": 0,
            "enginePower": {
              "hp": 0,
              "torque": 0
            },
            "powerSource": "string",
            "topSpeed": 0,
            "vehicleType": "string"
          }
        ],
        "oneOf": [
          {
            "$ref": "#/components/schemas/io.github.springwolf.examples.kafka.dtos.discriminator.VehicleElectricPayloadDto"
          },
          {
            "$ref": "#/components/schemas/io.github.springwolf.examples.kafka.dtos.discriminator.VehicleGasolinePayloadDto"
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "description": "Demonstrates the use of discriminator for polymorphic deserialization (not publishable)",
          "oneOf": [
            {
              "allOf": [
                { },
                {
                  "properties": {
                    "batteryCapacity": { },
                    "chargeTime": {
                      "format": "int32",
                      "type": "integer"
                    }
                  },
                  "type": "object"
                }
              ],
              "description": "Electric vehicle implementation of VehicleBase",
              "type": "object"
            },
            {
              "allOf": [
                { },
                {
                  "properties": {
                    "fuelCapacity": { }
                  },
                  "type": "object"
                }
              ],
              "description": "Gasoline vehicle implementation of VehicleBase",
              "type": "object"
            }
          ],
          "properties": {
            "enginePower": {
              "properties": {
                "hp": { },
                "torque": { }
              },
              "title": "EnginePower",
              "type": "object"
            },
            "powerSource": {
              "type": "string"
            },
            "topSpeed": { },
            "vehicleType": { }
          },
          "title": "VehicleBase",
          "type": "object"
        }
      },
      "io.github.springwolf.examples.kafka.dtos.discriminator.VehicleElectricPayloadDto": {
        "type": "object",
        "description": "Electric vehicle implementation of VehicleBase",
        "examples": [
          {
            "batteryCapacity": 0,
            "chargeTime": 0,
            "enginePower": {
              "hp": 0,
              "torque": 0
            },
            "powerSource": "string",
            "topSpeed": 0,
            "vehicleType": "string"
          }
        ],
        "allOf": [
          {
            "$ref": "#/components/schemas/io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase"
          },
          {
            "type": "object",
            "properties": {
              "batteryCapacity": {
                "type": "integer",
                "format": "int32"
              },
              "chargeTime": {
                "type": "integer",
                "format": "int32"
              }
            }
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "allOf": [
            {
              "description": "Demonstrates the use of discriminator for polymorphic deserialization (not publishable)",
              "oneOf": [
                { },
                {
                  "allOf": [
                    { },
                    {
                      "properties": {
                        "fuelCapacity": {
                          "format": "int32",
                          "type": "integer"
                        }
                      },
                      "type": "object"
                    }
                  ],
                  "description": "Gasoline vehicle implementation of VehicleBase",
                  "type": "object"
                }
              ],
              "properties": {
                "enginePower": {
                  "properties": {
                    "hp": { },
                    "torque": { }
                  },
                  "title": "EnginePower",
                  "type": "object"
                },
                "powerSource": {
                  "type": "string"
                },
                "topSpeed": { },
                "vehicleType": { }
              },
              "title": "VehicleBase",
              "type": "object"
            },
            {
              "properties": {
                "batteryCapacity": { },
                "chargeTime": { }
              },
              "type": "object"
            }
          ],
          "description": "Electric vehicle implementation of VehicleBase",
          "type": "object"
        }
      },
      "io.github.springwolf.examples.kafka.dtos.discriminator.VehicleGasolinePayloadDto": {
        "type": "object",
        "description": "Gasoline vehicle implementation of VehicleBase",
        "examples": [
          {
            "enginePower": {
              "hp": 0,
              "torque": 0
            },
            "fuelCapacity": 0,
            "powerSource": "string",
            "topSpeed": 0,
            "vehicleType": "string"
          }
        ],
        "allOf": [
          {
            "$ref": "#/components/schemas/io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase"
          },
          {
            "type": "object",
            "properties": {
              "fuelCapacity": {
                "type": "integer",
                "format": "int32"
              }
            }
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "allOf": [
            {
              "description": "Demonstrates the use of discriminator for polymorphic deserialization (not publishable)",
              "oneOf": [
                {
                  "allOf": [
                    { },
                    {
                      "properties": {
                        "batteryCapacity": { },
                        "chargeTime": {
                          "format": "int32",
                          "type": "integer"
                        }
                      },
                      "type": "object"
                    }
                  ],
                  "description": "Electric vehicle implementation of VehicleBase",
                  "type": "object"
                },
                { }
              ],
              "properties": {
                "enginePower": {
                  "properties": {
                    "hp": { },
                    "torque": { }
                  },
                  "title": "EnginePower",
                  "type": "object"
                },
                "powerSource": {
                  "type": "string"
                },
                "topSpeed": { },
                "vehicleType": { }
              },
              "title": "VehicleBase",
              "type": "object"
            },
            {
              "properties": {
                "fuelCapacity": { }
              },
              "type": "object"
            }
          ],
          "description": "Gasoline vehicle implementation of VehicleBase",
          "type": "object"
        }
      }
    },
    "messages": {
      "io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase": {
        "headers": {
          "$ref": "#/components/schemas/SpringKafkaDefaultHeaders-VehicleBase"
        },
        "payload": {
          "schemaFormat": "application/vnd.aai.asyncapi+json;version=3.0.0",
          "schema": {
            "$ref": "#/components/schemas/io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase"
          }
        },
        "name": "io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase",
        "title": "VehicleBase",
        "bindings": {
          "kafka": {
            "bindingVersion": "0.5.0"
          }
        }
      }
    }
  },
  "operations": {
    "vehicle-topic_receive_receiveExamplePayload": {
      "action": "receive",
      "channel": {
        "$ref": "#/channels/vehicle-topic"
      },
      "bindings": {
        "kafka": {
          "bindingVersion": "0.5.0"
        }
      },
      "messages": [
        {
          "$ref": "#/channels/vehicle-topic/messages/io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase"
        }
      ]
    }
  }
}