<!-- SPDX-License-Identifier: Apache-2.0 -->
<span>
  <span *ngIf="lowerBound() != undefined && upperBound() == undefined">
    {{ lowerBoundInclusive() ? ">=" : ">" }} {{ lowerBound() }}
  </span>
  <span *ngIf="lowerBound() == undefined && upperBound() != undefined">
    {{ upperBoundInclusive() ? "<=" : "<" }} {{ upperBound() }}
  </span>
  <span *ngIf="lowerBound() != undefined && upperBound() != undefined"
    >{{ lowerBoundInclusive() ? "[" : "(" }} {{ lowerBound() }}
    ..
    {{ upperBound() }} {{ upperBoundInclusive() ? "]" : ")" }}
  </span>
</span>
