<!-- SPDX-License-Identifier: Apache-2.0 -->
<app-header></app-header>
<main class="mat-typography">
  <app-sidenav>
    <section appNavigationTarget id="info">
      <app-info></app-info>
    </section>

    <section appNavigationTarget id="servers">
      <app-servers></app-servers>
    </section>

    <section appNavigationTarget id="channels">
      <app-channels> </app-channels>
    </section>

    <section appNavigationTarget id="schemas">
      <app-schemas></app-schemas>
    </section>
  </app-sidenav>
</main>
