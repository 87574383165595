<!-- SPDX-License-Identifier: Apache-2.0 -->
<mat-toolbar class="row space-between">
  <span>
    <a
      href="https://www.springwolf.dev"
      target="_blank"
      class="flex flex-column items-center"
    >
      <img
        src="assets/springwolf-logo.png"
        class="logo"
        alt="Logo"
        height="1024"
        width="1024"
      />
      Springwolf
    </a>
  </span>
  <h1 class="width-s-hide">{{ title }}</h1>
  <div class="flex flex-column items-center">
    <button mat-button [matMenuTriggerFor]="settings" data-testid="settings">
      <mat-icon style="color: black">settings</mat-icon>
      Settings
    </button>
    <mat-menu #settings="matMenu">
      <button
        mat-menu-item
        [matMenuTriggerFor]="group"
        *ngIf="0 < groups.length"
        data-testid="settings-group-menu"
      >
        Group
      </button>
      <mat-menu #group="matMenu">
        @for (group of groups; track group) {
        <button mat-menu-item (click)="changeGroup(group.value)">
          <mat-icon>{{
            isGroup == group.value
              ? "radio_button_checked"
              : "radio_button_unchecked"
          }}</mat-icon>
          {{ group.viewValue }}
        </button>
        }
      </mat-menu>

      <button
        mat-menu-item
        (click)="toggleIsShowBindings()"
        data-testid="settings-bindings"
      >
        <mat-icon>{{
          isShowBindings ? "check_box" : "check_box_outline_blank"
        }}</mat-icon>
        Show bindings
      </button>
      <button
        mat-menu-item
        (click)="toggleIsShowHeaders()"
        data-testid="settings-headers"
      >
        <mat-icon>{{
          isShowHeaders ? "check_box" : "check_box_outline_blank"
        }}</mat-icon>
        Show headers
      </button>
    </mat-menu>

    &nbsp;
    <a href="https://github.com/springwolf/springwolf-core" target="_blank">
      <mat-icon [svgIcon]="'github'" />
    </a>
  </div>
</mat-toolbar>
